.hidden {
  display: none;
}

.leaflet-control-basemapsSwitcher{
  height: auto;
  width: auto;
  display:flex;
}

.leaflet-control-basemapsSwitcher:hover{
  cursor: pointer;
}

.basemapImg{
  box-shadow: 2px 2px 5px rgb(0 0 0 / 65%);
  background-color: #fff;
  margin:5px;
  padding:5px;
  border-radius: 3px 3px;
}

.basemapImg div{
  background-size: 70px 70px;
  width: 70px;
  height: 70px;
  border-radius: 3px 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.basemapImg div .name{
  width:100%;
  height:auto;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 0;
  color:black;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding:1px;
}

.basemapImg div .check{
  width:auto;
  height:auto;
  border-radius: 0;
  display: flex;
  align-items: flex-end;
  font-weight: 500;
  padding:1px;
  content:''
}

.activeMap::before{
  color:white;
  content: '✔';
  width: 18px;
  height: 18px;
  background-color: #387B23;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
