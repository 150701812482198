html,
body,
mood-root {
  height: 100%;
  margin: 0;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

button {
  letter-spacing: normal !important;
  justify-content: left !important;
}

.ngx-slider-model-value {
  visibility: visible !important;

  border: 1px black solid;
  border-radius: 6px;

  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  z-index: 9999;
  background: white;
}

.leaflet-legend {
  line-height: 18px;
  color: #555;

  i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
  }
}

.info {
  padding: 6px 8px;
  // font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.info h4 {
  margin: 0 0 5px;
  color: #777;
}

// #region Leaflet compare
.leaflet-sbs-range {
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 999;
}
.leaflet-sbs-divider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
  width: 4px;
  background-color: #fff;
  pointer-events: none;
  z-index: 999;
}

.leaflet-sbs-range {
  -webkit-appearance: none;
  display: inline-block !important;
  vertical-align: middle;
  height: 0;
  padding: 0;
  margin: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.25);
  min-width: 100px;
  cursor: pointer;
  pointer-events: none;
  z-index: 999;
}
.leaflet-sbs-range::-ms-fill-upper {
  background: transparent;
}
.leaflet-sbs-range::-ms-fill-lower {
  background: rgba(255, 255, 255, 0.25);
}
/* Browser thingies */

.leaflet-sbs-range::-moz-range-track {
  opacity: 0;
}
.leaflet-sbs-range::-ms-track {
  opacity: 0;
}
.leaflet-sbs-range::-ms-tooltip {
  display: none;
}
/* For whatever reason, these need to be defined
* on their own so dont group them */

.leaflet-sbs-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
  border: 1px solid #ddd;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAABlBMVEV9fX3///+Kct39AAAAAnRSTlP/AOW3MEoAAAA9SURBVFjD7dehDQAwDANBZ/+l2wmKoiqR7pHRcaeaCxAIBAL/g7k9JxAIBAKBQCAQCAQC14H+MhAIBE4CD3fOFvGVBzhZAAAAAElFTkSuQmCC);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.leaflet-sbs-range::-ms-thumb {
  margin: 0;
  padding: 0;
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
  border: 1px solid #ddd;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAABlBMVEV9fX3///+Kct39AAAAAnRSTlP/AOW3MEoAAAA9SURBVFjD7dehDQAwDANBZ/+l2wmKoiqR7pHRcaeaCxAIBAL/g7k9JxAIBAKBQCAQCAQC14H+MhAIBE4CD3fOFvGVBzhZAAAAAElFTkSuQmCC);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.leaflet-sbs-range::-moz-range-thumb {
  padding: 0;
  right: 0;
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
  border: 1px solid #ddd;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAABlBMVEV9fX3///+Kct39AAAAAnRSTlP/AOW3MEoAAAA9SURBVFjD7dehDQAwDANBZ/+l2wmKoiqR7pHRcaeaCxAIBAL/g7k9JxAIBAKBQCAQCAQC14H+MhAIBE4CD3fOFvGVBzhZAAAAAElFTkSuQmCC);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.leaflet-sbs-range:disabled::-moz-range-thumb {
  cursor: default;
}
.leaflet-sbs-range:disabled::-ms-thumb {
  cursor: default;
}
.leaflet-sbs-range:disabled::-webkit-slider-thumb {
  cursor: default;
}
.leaflet-sbs-range:disabled {
  cursor: default;
}
.leaflet-sbs-range:focus {
  outline: none !important;
}
.leaflet-sbs-range::-moz-focus-outer {
  border: 0;
}

//endregion

.layer-browser-tab-body {
  padding: 8px;
  background-color: rgb(211, 211, 211, 0.15);
}

.custom-accordion.mat-accordion {
  min-height: 60px;

  div > :not(.mat-expanded).mat-expansion-panel {
    border-radius: 0;
  }

  div:last-of-type > .mat-expansion-panel {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  div:first-of-type > .mat-expansion-panel {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  div > .mat-expanded.mat-expansion-panel {
    border-radius: 4px;
    margin: 8px 0;
  }
}

.mat-mdc-menu-item.warning {
  color: #f44336;

  mat-icon {
    color: #f44336;
  }
}

.mat-mdc-menu-panel {
  max-width: 100% !important;
}

.snackbar-success {
  // color: black;
  background-color: rgb(46, 204, 113);

  // background-color: rgba(144, 255, 144, 0.85);
}

.snackbar-error {
  background-color: #b00020;
}

.loading-spinner {
  width: 25px;
  height: 25px;
  border: 5px solid darkblue;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.mat-mdc-tooltip {
  white-space: pre-line;
}

// TREE
.tree-node {
  display: flex;
  flex-direction: row;
  cursor: pointer;

  .name {
    align-self: center;
  }

  .spacer {
    display: flex;
    flex: 1 1 auto;
  }

  &:hover {
    background-color: rgba(211, 211, 211, 0.3);
  }
}

.sub-nodes {
  padding-left: 40px;
}

.mat-mdc-tooltip {
  font-size: 14px;
  word-wrap: break-word;
  hyphens: auto;
  text-align: justify;
}

.info-dialog-tab-body {
  max-height: 450px;
}

// TODO: Conditional if the user is 'expert' or something
.mood-map-watermark {
  // visibility: hidden;
}

.mood-map-export-container {
  border-radius: 4px;
  padding: 12px;
  background-color: white;
}

.mood-map-export-legend {
  bottom: 100px;

  .legend > .entries {
    padding-top: 0;
  }
}

.leaflet-control-export {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-export-toggle {
  width: 36px;
  height: 36px;
  background-image: url("assets/download-multiple-outline.svg");
  background-position: 50% 50%;
  background-size: 24px;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-control-export .leaflet-control-export-list,
.leaflet-control-export-expanded .leaflet-control-export-toggle {
  display: none;
}
.leaflet-control-export-expanded .leaflet-control-export-list {
  display: block;
  position: relative;
}
.leaflet-control-export-expanded {
  padding: 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-export-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}
.leaflet-control-export-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.pane-button {
  width: 100%;

  button > span {
    line-height: 42px !important;
  }
}

// Table config
.mood-table {
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid black;
    min-width: 60px;
    height: 20px;
    padding: 8px;
    white-space: nowrap;
  }

  td .loading-container {
    text-align: center;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }
}

.as-split-gutter-icon:hover {
  cursor: nwse-resize;
}

h2.admin-header {
  margin-top: 16px;
  margin-bottom: 10px;
  text-align: center;
}

.slide-control {
  display: grid;
  grid-template-columns: auto 1fr;

  gap: 0 8px;

  .slide-label {
    line-height: 24px;
  }

  mat-hint {
    grid-column: span 2;
    padding-bottom: 14px;
    font-size: 75%;
  }
}

mat-option {
  .mdc-list-item__primary-text {
    display: inline-block;
    flex-grow: 1;
  }
}
